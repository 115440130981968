import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    containerWrapper: {
        backgroundColor: "#2062c7",
        minHeight: "100vh",
    },
    itemWrapper: {
        maxWidth: 400,
        width: "100%",
    },
    container: {
        paddingTop: theme.spacing(8),
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    logo: {
        cursor: "pointer",
        height: "3rem",
    },
    marginBtm: {
        marginBottom: theme.spacing(2),
    },
    centerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
