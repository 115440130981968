import React from "react";
import { namespacedLocalStorage, signInService } from "@surelync/common";

export type Auth = {
    clientLogo: string;
    namespacedLocalStorage: any;
    signInService: any;
    setClientLogo: (imageBase64: string) => void;
};

export const defaultState = Object.freeze({
    clientLogo: "",
    namespacedLocalStorage,
    signInService,
});

export const AuthContext = React.createContext<Partial<Auth>>(defaultState);
export const AuthContextProvider = AuthContext.Provider;
export const AuthContextConsumer = AuthContext.Consumer;
