import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 0),
    },
    loading: {
        position: "absolute",
        top: "50%",
        right: "10%",
        marginTop: -10,
    },
}));
