import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";

interface IProps {
    label: string;
    isLoading: boolean;
}

const LoadingButton = ({ label, isLoading }: IProps) => {
    const classes = useStyles();

    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
            data-testid="loading-button"
        >
            {label}
            {isLoading && <CircularProgress className={classes.loading} size={20} />}
        </Button>
    );
};

export default LoadingButton;
