import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";

interface IProps {
    error: string;
}

const ErrorMessage: React.FC<IProps> = ({ error }) => {
    const classes = useStyles();

    return (
        <Typography variant="body1" className={classes.errorStyle} align="center">
            {error}
        </Typography>
    );
};

export default ErrorMessage;
