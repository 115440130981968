import { lighten, makeStyles } from "@material-ui/core/styles";

const bgPercent = 0.9;
const bgHoveredPercent = 0.7;

export const useStyles = makeStyles((theme) => ({
    paperContent: {
        width: "100%",
        padding: theme.spacing(6, 5, 0),
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 0),
    },
    submitLink: {
        margin: theme.spacing(3, 0, 0),
        color: theme.palette.secondary.dark,
        backgroundColor: lighten(theme.palette.primary.main, bgPercent),
        "&:hover": {
            backgroundColor: lighten(theme.palette.primary.main, bgHoveredPercent),
        },
    },
    formGroup: {
        marginBottom: theme.spacing(2),
    },
    clientLogo: {
        display: "block",
        margin: "0 auto",
        marginBottom: theme.spacing(2),
        maxWidth: "100%",
        maxHeight: 400,
    },
    logo: {
        cursor: "pointer",
        height: "3rem",
    },
    centerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    iconWrapper: {
        marginRight: theme.spacing(1),
    },
    subtitle: {
        margin: theme.spacing(2, 0, 2),
    },
}));
