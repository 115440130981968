import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Fade, Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import TermsAndPrivacy from "../TermsAndPrivacy";
import HelpLogin from "../HelpLogin/HelpLogin";
import { AssetsService } from "@surelync/common";
import buildJson from "../../build.json";
import { useTranslation } from "react-i18next";

interface IProps {
    title: string;
}

const FormContainer: React.FC<IProps> = ({ children, title }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        let isMount = true;
        const timerId = setTimeout(() => {
            isMount && setOpen(true);
        }, 150);

        return () => {
            isMount = false;
            clearTimeout(timerId);
        };
    }, []);

    return (
        <div className={classes.containerWrapper}>
            <Fade in={open}>
                <Grid container justify="center">
                    <Grid item className={classes.itemWrapper}>
                        <Box pt={10}>
                            {title ? (
                                <Typography
                                    align="center"
                                    className={clsx([classes.centerWrapper, classes.marginBtm])}
                                    gutterBottom
                                    style={{ color: "white" }}
                                    variant="h6"
                                >
                                    {title}
                                </Typography>
                            ) : (
                                <Box mt={10} />
                            )}
                            <Paper square className={classes.paper}>
                                {children}
                            </Paper>
                            <div className={clsx([classes.centerWrapper, classes.marginBtm])}>
                                <TermsAndPrivacy>
                                    <HelpLogin />
                                </TermsAndPrivacy>
                            </div>
                            <div className={classes.centerWrapper}>
                                <Link to="/login">
                                    <img className={classes.logo} src={`${AssetsService.basePath()}images/home-logo.png`} alt="surelync logo" />
                                </Link>
                            </div>
                            <div className={classes.centerWrapper}>
                                <p style={{ color: "white" }}>
                                    {t("version")}: {buildJson.version}
                                </p>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Fade>
        </div>
    );
};

export default FormContainer;
