import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    paperContent: {
        width: "100%",
        padding: theme.spacing(6, 5, 4),
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    formGroup: {
        marginBottom: theme.spacing(2),
    },
    clientLogo: {
        display: "block",
        margin: "0 auto",
        marginBottom: theme.spacing(2),
        maxWidth: "100%",
        maxHeight: 400,
    },
    logo: {
        cursor: "pointer",
        height: "3rem",
    },
    centerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    iconWrapper: {
        marginRight: theme.spacing(1),
    },
    subtitle: {
        margin: theme.spacing(2, 0, 4),
    },
}));
