import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { UserApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { InputCustom, localeService } from "@surelync/common";
import { trimEmailValue } from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { useStyles } from "./styles";
import FormContainer from "../components/FormContainer";
import { AuthContext } from "../auth.context";
import ErrorMessage from "../components/ErrorMessage";
import LoadingButton from "../components/LoadingButton";

const ForgotpasswordPage = () => {
    const { clientLogo, namespacedLocalStorage } = useContext(AuthContext);
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const userApiClient = new UserApiClient(namespacedLocalStorage);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validate: (values) => {
            const errors: any = {};

            if (!values.email) {
                errors.email = t("required");
            }

            return errors;
        },
        onSubmit: async ({ email }) => {
            setIsLoading(true);
            setError("");
            try {
                const result = await userApiClient.triggerPasswordReset(
                    email,
                    `${window.origin}/surelync/updatepassword?email=${encodeURIComponent(email)}`,
                    null,
                    namespacedLocalStorage.getItem("app-name")
                );
                const msg = result.message === "Please check your inbox" ? t("checkEmail") : result.message;

                setMessage(msg);
            } catch (err) {
                setError(generateErrorMessage(err));
            }
            setIsLoading(false);
        },
    });

    useEffect(() => {
        const subscription = localeService.i18nLoader$.subscribe(() => (document.title = `${t("forgotPassword")} - SureLync`));
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localeService.locale]);

    return (
        <FormContainer title={!message ? t("forgotPassword") : ""}>
            <div className={classes.paperContent}>
                {clientLogo ? <img alt="client logo" className={classes.clientLogo} src={clientLogo} /> : null}
                {message ? (
                    <Typography variant="h6" gutterBottom align="center">
                        {message}
                    </Typography>
                ) : null}
                {!message && (
                    <>
                        <Typography variant="body2" gutterBottom align="left" className={classes.subtitle}>
                            {t("enterEmailAddressToResetPassword")}
                        </Typography>
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                            <div className={classes.formGroup}>
                                <InputCustom
                                    data-testid="email"
                                    disabled={isLoading}
                                    error={formik.errors.email}
                                    label={t("email")}
                                    name="email"
                                    theme={theme}
                                    touched={formik.touched.email}
                                    type="email"
                                    value={formik.values.email}
                                    onBlur={(e) => {
                                        formik.setFieldValue("email", trimEmailValue(e.target.value));
                                        formik.handleBlur(e);
                                    }}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {error && <ErrorMessage error={`${t(error)}`} />}
                            <LoadingButton label={t("resetPassword")} isLoading={isLoading} />
                        </form>
                    </>
                )}
            </div>
        </FormContainer>
    );
};

export default ForgotpasswordPage;
