import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, SvgIcon, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { InputCustom, localeService } from "@surelync/common";
import { trimEmailValue } from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { useTranslation } from "react-i18next";
import { AuthApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { useStyles } from "./styles";
import { AuthContext } from "../auth.context";
import FormContainer from "../components/FormContainer";
import LoadingButton from "../components/LoadingButton";
import ErrorMessage from "../components/ErrorMessage";
import { validate } from "./validate";

const LoginPage = () => {
    const { clientLogo, namespacedLocalStorage, signInService } = useContext(AuthContext);
    const authApiClient = useMemo(() => (new AuthApiClient(namespacedLocalStorage)), [namespacedLocalStorage]);

    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const [providers, setProviders] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();
    const token = localStorage.getItem("token");

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validate: validate(),
        onSubmit: async ({ email, password }) => {
            setIsLoading(true);
            setError(null);
            try {
                // Sets auth cookie for future requests.
                await authApiClient.tokenViaBasicAuth(email, password);
                await authenticate();
            } catch (error) {
                setError(generateErrorMessage('incorrectUsernameOrPassword'));
                authApiClient.clearJwtToken();
                throw error;
            } finally {
                setIsLoading(false);
            }
        },
    });

    const setUser = useCallback(async (whoami) => {
        signInService.signIn(whoami);
        try {
            const response = await authApiClient.getUser(whoami.username);
            signInService.setUser(response.data);
        } catch (error) {
            console.error(error);
            // parse email and display First/Last name at the header.
            signInService.setUser({
                email: whoami.username,
            });
        }
    }, [authApiClient, signInService]);

    const authenticate = useCallback(async () => {
        const whoAmI = await authApiClient.whoAmI();
        if (whoAmI.roles.includes("Policyholder")) throw new Error("Access denied");
        if (!whoAmI || whoAmI.username === "anonymous") return;
        await setUser(whoAmI);

        // After log-in, the user gets redirected to the page for the deep link.
        const pathname = !whoAmI ? namespacedLocalStorage.getItem("redirect") : "/";

        history.push(pathname);
        localStorage.removeItem("token");
        namespacedLocalStorage.removeItem("redirect");
    }, [authApiClient, history, namespacedLocalStorage, setUser]);

    useEffect(() => {
        const fetchData = async () => {
            const whoami = await authApiClient.whoAmI();
            if ((!whoami) || !whoami.party || whoami.username === "anonymous") {
                const pathname = window.location.pathname.startsWith("/surelync")
                    ? window.location.pathname.replace("/surelync", "")
                    : window.location.pathname;
                signInService.signOut();
                namespacedLocalStorage.setItem("redirect", pathname);
                history.push("/login");
            } else {
                await setUser(whoami);
            }
        };
        if (token) return;
        fetchData();
    }, [authApiClient, history, namespacedLocalStorage, setUser, signInService, token]);

    useEffect(() => {
        if (token) return;
        authApiClient.whoAmI().then((whoami) => {
            if (whoami) {
                authenticate();
            }
        });
    }, [signInService, token, authenticate, authApiClient]);

    useEffect(() => {
        const providers = async () => {
            const listProviders = await authApiClient.listSamlProviders();
            const result = Object.entries(listProviders).map(([url, name]) => ({
                url,
                name,
            }));

            if (result.length) {
                setProviders(result);
            }

            if (!token) return;
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await authApiClient.getToken(config);
            await authenticate();
        };

        providers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = localeService.i18nLoader$.subscribe(() => (document.title = `${t("login")} - SureLync`));
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localeService.locale]);

    // Prevent flickering when redirect back from Facebook
    // do not show form for a moment before authenticating
    if (token) {
        return null;
    }

    return (
        <FormContainer title={t("loginToAccount")}>
            <div className={classes.paperContent}>
                {clientLogo ? <img alt="client logo" className={classes.clientLogo} src={clientLogo} /> : null}
                {providers ? (
                    <>
                        {providers.map(({ url, name }) => (
                            <Button
                                key={name}
                                href={`${url}?forwardUrl=${window.location}`}
                                color="primary"
                                disabled={isLoading}
                                fullWidth
                                className={classes.submit}
                                variant="outlined"
                            >
                                <SvgIcon color="primary" fontSize="small" className={classes.iconWrapper}>
                                    <path
                                        fill="currentColor"
                                        d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
                                    />
                                </SvgIcon>
                                {t("signInWithGoogle")}
                            </Button>
                        ))}
                        <Typography variant="subtitle1" gutterBottom align="center" className={classes.subtitle}>
                            - {t("or")} -
                        </Typography>
                    </>
                ) : (
                    <div data-testid="no-providers" />
                )}
                <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <div className={classes.formGroup}>
                        <InputCustom
                            data-testid="email"
                            disabled={isLoading}
                            error={formik.errors.email}
                            label={t("email")}
                            name="email"
                            theme={theme}
                            touched={formik.touched.email}
                            value={formik.values.email}
                            onBlur={(e) => {
                                formik.setFieldValue("email", trimEmailValue(e.target.value));
                                formik.handleBlur(e);
                            }}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <InputCustom
                            data-testid="password"
                            disabled={isLoading}
                            error={formik.errors.password}
                            theme={theme}
                            label={t("password")}
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            touched={formik.touched.password}
                            onBlur={formik.handleBlur}
                            type="password"
                        />
                    </div>
                    {error && <ErrorMessage error={`${t(error)}`} />}
                    <LoadingButton label={t("login")} isLoading={isLoading} data-testid="login-btn" />
                </form>
            </div>
            <Button component={Link} disabled={isLoading} to={"/forgotpassword"} fullWidth color="primary" className={classes.submitLink}>
                {t("forgotPassword")}
            </Button>
            {!isLoading && <div data-testid="submitted" />}
        </FormContainer>
    );
};

export default LoginPage;
