import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { history, i18n, theme } from "@surelync/common";
import { I18nextProvider } from "react-i18next";
import LoginPage from "./@LoginPage/LoginPage";
import ForgotpasswordPage from "./@ForgotpasswordPage/ForgotpasswordPage";
import UpdatepasswordPage from "./@UpdatepasswordPage/UpdatepasswordPage";
import { AuthContextProvider, defaultState } from "./auth.context";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import queryString from "query-string";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync",
    seed: "auth",
});

function Root() {
    const [state, setState] = useState(defaultState);

    const blobApiClient = new BlobApiClient(state.namespacedLocalStorage);

    useEffect(() => {
        const getClientLogo = async () => {
            try {
                const imageBase64 = await blobApiClient.retrievedTagGlobal("client-logo");
                setState({ ...state, clientLogo: imageBase64 });
            } catch (error) {
                // console.error(error);
            }
        };

        getClientLogo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StylesProvider generateClassName={generateClassName}>
            <AuthContextProvider value={state}>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Router history={history}>
                            <Switch>
                                <Route path="/login" component={LoginPage} />
                                <Route path="/forgotpassword" component={ForgotpasswordPage} />
                                <Route
                                    path="/updatepassword"
                                    render={(props) => <UpdatepasswordPage email={queryString.parse(props.location.search).email as string} />}
                                />
                            </Switch>
                        </Router>
                    </ThemeProvider>
                </I18nextProvider>
            </AuthContextProvider>
        </StylesProvider>
    );
}

export default Root;
