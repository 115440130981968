import React from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkButton } from "@material-ui/core";
import { localeService } from "@surelync/common";

enum EHelpSpace {
    "en-GB" = "EN",
    "ja-JP" = "JP",
    "id-ID" = "ID",
}

type IProps = Record<string, unknown>;

const HelpLogin: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const value = EHelpSpace[localeService.locale] || EHelpSpace["en-GB"];

    return (
        <LinkButton
            data-testid="help-login"
            target="_blank"
            href={`https://surelync.atlassian.net/wiki/spaces/${value}`}
            rel="noopener"
            style={{ color: "white" }}
        >
            {t("loginHelp")}
        </LinkButton>
    );
};

export default HelpLogin;
