import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IconButton, InputAdornment, Link as LinkButton, Typography } from "@material-ui/core";
import { Visibility, VisibilityOutlined } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { AuthApiClient, UserApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { errors, validatePasswords } from "@blocksure/blocksure-core/dist/src/services/api-clients/UserApiClient";
import { InputCustom, localeService, PasswordValidation, RESET_EMAIL_NAME } from "@surelync/common";
import { IError } from "./models";
import { useStyles } from "./styles";
import FormContainer from "../components/FormContainer";
import { AuthContext } from "../auth.context";
import LoadingButton from "../components/LoadingButton";

const inputErrors = {
    short: errors[0],
    long: null,
    capital: errors[2],
    lower: errors[3],
    number: errors[4],
};

const JWT_ERRORS = {
    "token-reuse": "pwdResetConsumed",
    "token-expired": "pwdResetExpired"
};

type UpdatepasswordPageT = {
    email: string;
};

const UpdatepasswordPage: FC<UpdatepasswordPageT> = ({ email }) => {
    const { clientLogo, namespacedLocalStorage, signInService } = useContext(AuthContext);
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const [visability, setVisability] = useState<boolean>(false);
    const [errors, setErrors] = useState<IError>(inputErrors);
    const [isShowValidationLabel, setIsShowValidationLabel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string>(null);
    // const [errorReset, setErrorReset] = useState(null);
    const [successReset, setSuccessReset] = useState<boolean>(false);
    const history = useHistory();
    const userEmail = email || localStorage.getItem(RESET_EMAIL_NAME);
    const authApiClient = new AuthApiClient(namespacedLocalStorage);
    const userApiClient = new UserApiClient(namespacedLocalStorage);

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.password && !validatePasswords(values.password)) {
                errors.password = t("required");
            }

            return errors;
        },
        onSubmit: async ({ password }) => {
            if (validatePasswords(password)) {
                return;
            }

            setLoading(true);
            if (token) {
                const config = Object.assign({
                    headers: { Authorization: `Bearer ${token}` },
                });

                try {
                    await authApiClient.getToken(config);
                    await userApiClient.updateCurrentUserPassword(password);
                    await authApiClient.clearJwtToken();
                    setIsShowValidationLabel(false);
                    setSuccessReset(true);
                    window.localStorage.removeItem(RESET_EMAIL_NAME);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            } else {
                console.error("token not found");
            }
            setLoading(false);
            localStorage.removeItem("token");
        },
    });

    const handleChange = (password) => {
        const errorResponse = validatePasswords(password);
        if (!errorResponse) {
            setErrors({});
            return;
        }

        setErrors(errorResponse);
    };

    useEffect(() => {
        const loadToken = async () => {
            const token = localStorage.getItem("token");
            const jwtError = localStorage.getItem("jwtError");
            if (token) {
                setToken(token);
                // person can be login in with one user
                // and press reset password button from the email for other user
                signInService.signOut();
                await authApiClient.clearJwtToken();
            } else if (jwtError) {
                localStorage.removeItem("jwtError");
                setErrors({ jwtError } as any);
            } else {
                history.push("/login");
            }
        };

        loadToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscription = localeService.i18nLoader$.subscribe(() => (document.title = `${t("resetPassword")} - SureLync`));
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localeService.locale]);

    return (
        <FormContainer title={t("resetPassword")}>
            <div className={classes.paperContent}>
                {clientLogo ? <img alt="client logo" className={classes.clientLogo} src={clientLogo} /> : null}

                {successReset ? (
                    <>
                        <Typography variant="subtitle1" gutterBottom align="center" className={classes.subtitle}>
                            {t("yourPasswordWasReset")}
                        </Typography>
                        <Typography align="center" variant="body1">
                            <LinkButton component={Link} to="/login" className={classes.labelMarginLeft}>
                                <Typography component="span" variant="body1">
                                    {t("clickHereTologIn")}
                                </Typography>
                            </LinkButton>
                        </Typography>
                    </>
                ) : errors.jwtError ? (
                    <>
                        <Typography variant="subtitle1" gutterBottom align="center" className={classes.subtitle}>
                            {t(JWT_ERRORS[errors.jwtError])}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom align="center" className={classes.subtitle}>
                            {t("pwdResetGenerate")}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="subtitle1" gutterBottom align="left" className={classes.subtitle}>
                            {t("enterNewPassword")}
                        </Typography>
                        <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                            <div className={classes.formGroup}>
                                <input hidden id="username" required={false} name="username" autoComplete="username" type="email" value={userEmail} />
                                <InputCustom
                                    autoComplete="new-password"
                                    data-testid="new-password"
                                    error={formik.errors.password}
                                    theme={theme}
                                    label={t("password")}
                                    name="password"
                                    onChange={(event) => {
                                        formik.handleChange(event);
                                        handleChange(event.currentTarget.value);
                                    }}
                                    value={formik.values.password}
                                    touched={formik.touched.password}
                                    onBlur={formik.handleBlur}
                                    type={visability ? "text" : "password"}
                                    disabled={loading || successReset}
                                    onFocus={() => setIsShowValidationLabel(true)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search button"
                                                data-testid="submit"
                                                disabled={loading}
                                                size="small"
                                                onClick={() => setVisability(!visability)}
                                            >
                                                {visability ? <Visibility /> : <VisibilityOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                            <div className={classes.formGroup}>
                                {isShowValidationLabel && (
                                    <div>
                                        <PasswordValidation errors={errors} theme={theme} />
                                    </div>
                                )}
                            </div>
                            <LoadingButton label={t("save")} isLoading={loading || successReset} />
                        </form>
                    </>
                )}
            </div>
        </FormContainer>
    );
};

export default UpdatepasswordPage;
