import React from "react";
import { Grid, Link as LinkButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type IProps = Record<string, unknown>;

const TermsAndPrivacy: React.FC<IProps> = ({ children }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" justify="center" spacing={1} wrap="nowrap">
            <Grid item style={{ textAlign: "center" }}>
                <LinkButton target="_blank" href="https://www.blocksure.com/terms-conditions/" className={classes.termsButton}>
                    {t("termsOfUse")}
                </LinkButton>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" className={classes.termsButton}>
                    -
                </Typography>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
                {children}
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" className={classes.termsButton}>
                    -
                </Typography>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
                <LinkButton target="_blank" href="https://www.blocksure.com/privacy/" className={classes.termsButton}>
                    {t("privacyPolicy")}
                </LinkButton>
            </Grid>
        </Grid>
    );
};

export default TermsAndPrivacy;
